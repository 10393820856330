import React, { Component } from "react";
import styles from "./waiting.module.css";
import { io } from "socket.io-client";
import Loading from "../loading/loading";
import { Cookies } from "react-cookie";
let cookies = new Cookies();
let socket;
export default class waiting extends Component {
  state = {
    waiting: true,
    bot: false
  };
  componentDidMount() {
    socket = io();
    socket.on("connect", () => {
      document.cookie = `playerid=${socket.id}`;
    });

    setTimeout(() => {
      socket.emit("bot");
      if (this.state.waiting) {
        this.setState({ waiting: false, bot: true });
      }
    }, Math.floor(Math.random() * 5000) + 2000);
    
    socket.emit("join", cookies.get("playerid"));
    socket.on("socket", (msg) => {
      this.setState({ waiting: false });
    });
    socket.on("connect_error", (err) => {
      console.log("Error Connecting Socket : ", err);
    });
  }
  render() {
    return (
      <div className={styles.body}>
        {(this.state.waiting && (
          <div className={styles.container}>
            <div>
              <h2 className={styles.title}>
                Waiting for the next available tapper...
              </h2>
            </div>
          </div>
        )) || <Loading socket={socket} bot={this.state.bot} />}
      </div>
    );
  }
}
