import "./App.css";
import { useEffect, Fragment, useState } from "react";
import Waiting from "./components/waiting/waiting";
import Result from "./components/result/result";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Start from "./components/start/start";
import Block from "./components/block/block";
import Disconnected from "./components/disconnected/disconnected";
function App() {
  const [browser, setBrowser] = useState("");
  const [loading, setLoading] = useState(false);
  const [highScores, setHighScores] = useState([]);
  const [players, setPlayers] = useState(0);
  useEffect(() => {
    setLoading(true);
    fetch("/browser")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        setBrowser(data.success);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch("/api/getHighscores")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result.success) {
          setHighScores(result.success.success);
          setPlayers(result.success.players);
        }
      })
      .catch((errr) => {});
  }, []);
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            component={() => {
              if (browser === "ok") {
                sessionStorage.removeItem("stillplay");
                return (
                  <Start
                    highScores={highScores}
                    players={players}
                    loading={loading}
                  />
                );
              } else {
                return <Block loading={loading} highScores={highScores} players={players} />;
              }
            }}
          ></Route>
          <Route path="/game" exact component={Waiting}></Route>
          <Route path="/abort" exact component={Disconnected}></Route>
          <Route
            path="/result"
            exact
            component={() => <Result></Result>}
          ></Route>
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
