import React, { Component } from "react";
import styles from "../result/result.module.css";
export default class waiting extends Component {
  render() {
    return (
      <div className={styles.body}>
        <div className={styles.container}>
          <div>
            <h2 className={styles.title}>Opponent Disconnected!</h2>
          </div>
          <div>
            <a className={styles.playagain} href="/game">
              Play again!
            </a>
          </div>
        </div>
      </div>
    );
  }
}
