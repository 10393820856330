import React, { Component } from "react";
import styles from "./game.module.css";
import phrases from "./phrases";
export default class waiting extends Component {
  state = {
    waiting: true,
    count: 15,
    taps: 0,
    Optaps: 0,
    result: "",
    playAgainButton: false
  };
  componentDidMount() {
    const winPhrase = phrases.win[Math.floor(Math.random() * phrases.win.length)];
    const loosePhrase = phrases.lose[Math.floor(Math.random() * phrases.lose.length)];
    if (this.props.bot) {
      const tapMilis = 1000 / ((Math.floor(Math.random() * (115 - 65 + 1) ) + 65) / 15);
      const botTapperInterval = setInterval(() => {
        if (this.state.count) {
          this.setState({ Optaps: this.state.Optaps + 1 });
        }
      }, tapMilis);

      const timerInterval = setInterval(() => {
        if (this.state.count <= 1) {
          clearInterval(botTapperInterval);
          clearInterval(timerInterval);
          this.setState({ waiting: false });
          setTimeout(() => {
            this.setState({ playAgainButton: true });
          }, 1250);
          if (this.state.taps > this.state.Optaps) {
            this.setState({ result: winPhrase });
          } else if (this.state.taps < this.state.Optaps) {
            this.setState({ result: loosePhrase });
          } else {
            this.setState({ result: "Tie! You've found a worthy opponent." });
          }
          this.props.socket.emit("botFinished", {
            taps: this.state.taps,
            Optaps: this.state.Optaps,
          });
        } else {
          this.setState({ count: this.state.count - 1 });
        }
      }, 1000);
    } else {
      this.props.socket.emit("started");
      this.props.socket.on("counts", (count) => {
        this.setState({ Optaps: count });
      });
      this.props.socket.on("abort", () => {
        window.location = "/abort";
      });
      this.props.socket.on("time", async (time) => {
        if (time > 0) {
          this.setState({ count: time });
        } else {
          this.setState({ waiting: false });
          setTimeout(() => {
            this.setState({ playAgainButton: true });
          }, 1250);
          if (this.state.taps > this.state.Optaps) {
            this.setState({ result: winPhrase });
          } else if (this.state.taps < this.state.Optaps) {
            this.setState({ result: loosePhrase });
          } else {
            this.setState({ result: "Tie! You've found a worthy opponent." });
          }
          await this.props.socket.emit("finished", {
            taps: this.state.taps,
            Optaps: this.state.Optaps,
          });
        }
      });
    }
  }
  render() {
    return (
      <div className={styles.body}>
        {this.state.waiting ? (
          <div className={styles.container}>
            <div>
              <h2 className={styles.title}>
                {this.state.count} seconds remaining
              </h2>
            </div>
            <div>Opponent's taps {this.state.Optaps}</div>
            {!this.props.bot ? (
            <div
              onClick={async () => {
                await this.setState({ taps: this.state.taps + 1 });
                this.props.socket.emit("tap", this.state.taps);
              }}
              className={styles.box}
            >
              <h2>{this.state.taps}</h2>
            </div>
            ) : (
            <div
              onClick={() => { this.setState({ taps: this.state.taps + 1 }); }}
              className={styles.box}
            >
              <h2>{this.state.taps}</h2>
            </div>
            ) }
          </div>
        ) : (
          <div className={styles.body}>
            <div className={styles.container}>
              <div>
                <h2 className={styles.title}>{this.state.result}!</h2>
              </div>
              <div className={styles.scores}>
                <div>Your score : {this.state.taps}</div>
                <div>Opponent's score : {this.state.Optaps}</div>
              </div>
              <div>
                {this.state.playAgainButton ? (
                  <a className={styles.playagain} href="/game">
                    Play again!
                  </a>
                ) : (<br></br>)}
                <br></br><br></br>
                <a className={styles.imdone} href="/">
                  I'm done
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
