import React, { Component } from "react";
import styles from "./result.module.css";
export default class waiting extends Component {
  state = {
    result: "win",
    taps: 12,
    Optaps: 10,
  };
  componentDidMount() {
    this.setState({
      taps: this.props.taps.taps,
      Optaps: this.props.taps.Optaps,
      result: this.props.taps.taps >= this.props.taps.Optaps ? "Win" : "Lose",
    });
  }
  render() {
    return (
      <div className={styles.body}>
        <div className={styles.container}>
          <div>
            <h2 className={styles.title}>You {this.state.result}!</h2>
          </div>
          <div>Your score : {this.state.taps}</div>
          <div>Opponent's score : {this.state.Optaps}</div>
          <div>
            <a className={styles.playagain} href="/game">
              Play again!
            </a>
          </div>
        </div>
      </div>
    );
  }
}
