import React, { Component } from "react";
import styles from "./start.module.css";

export default class start extends Component {
  state = {
    players: 0,
    highScores: [],
    loading: false,
  };
  componentDidMount() {
    this.setState({
      highScores: this.props.highScores,
      players: (Number(this.props.players) === 0) ? (Math.floor(Math.random() * 4) + 1) : (this.props.players)
    });
  }
  render() {
    const webGameContainer = sessionStorage.getItem("stillplay") ? styles.webContainer : " ";
    return (
      <div className={styles.body}>
        {!this.state.loading && (
          <div className={styles.container+" "+webGameContainer}>
            <div className={styles.description}>
              <div>
                <h3>Welcome to tapreallyfast.com</h3>
              </div>
              <div className={styles.text}>
                You're about to be paired up with a random tapper somewhere in
                the world.
              </div>
              <div className={styles.text}>Let's see who can tap faster!</div>
              <div>
                <a href="/game" className={styles.start}>
                  Start!
                </a>
              </div>
              <div>
                There are {this.state.players} people playing right now.
              </div>
            </div>
            <hr className={styles.hr} />
            <div className={styles.score}>
              <h3>High Scores</h3>
              <div className={styles.score}>
                {this.state.highScores.map((score, index) => {
                  return (
                    <div key={index}>
                      {score.score} - {score.date}{" "}
                    </div>
                  );
                })}
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}
