import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPaper, faMobile } from "@fortawesome/free-solid-svg-icons";
import styles from "./block.module.css";
import Start from "../start/start";
export default class block extends Component {
  state = {
    loading: true,
    block: sessionStorage.getItem("stillplay")
  };
  componentDidMount() {
    this.setState({ loading: this.props.loading });
  }
  render() {
    return (
      <>
      {!this.state.block ? (
        <div className={styles.body}>
        {!this.state.loading && (
          <Fragment>
            <div className={styles.stopLogo}>
              <FontAwesomeIcon icon={faHandPaper}></FontAwesomeIcon>
            </div>
            <div className={styles.text}>
              tapreallyfast.com is best on your{" "}
              <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
            </div><br></br>
            <div>
              <button className={styles.stillplay} onClick={() => { sessionStorage.setItem("stillplay", "true"); this.setState({ block: sessionStorage.getItem("stillplay") }); }}>
                I'd still like to play
              </button>
            </div>
          </Fragment>
        )}
      </div>
      ) : (
        <Start highScores={this.props.highScores} players={this.props.players} loading={this.props.loading}/>
      )}
      </>
    );
  }
}
