const phrases = {
    win: [
        "Yeehaw, you’ve won!",
        "I’ll be jitterbugged! You've won!",
        "Va-va-voom! You’ve won!",
        "Stiffen the wombats! You're the winner!",
        "Gadzooks! You've won!",
        "Snap my garters, you've won!",
        "Mamma mia, you've won!",
        "Holy moly, you've won!",
        "Winner winner, turducken dinner!",
        "Sweet Zombie Jesus! You've won!",
        "You've won! Have yourself a coke.",
        "Jumpin’ Jesus on a pogostick!.. you've won!",
        "How much tap can a tap tap tap if a tap tap could tap tap (you won)",
        "Jupiter's thunder! You've won!",
        "Pat yourself on the back for out-tapping a random tapper!",
        "Just give it a little tappity tap tap (you won)",
        "You've won! Brag to all your friends.",
        "Sweet jelly on a log! You've won!",
        "JEHOSHAPHAT! You've won!",
        "LORD LOVE A DUCK! You've won!",
        "Twisted armpit hairs!! You've won!",
        "Stone the crows! You've won!",
        "Zickity bam! You've won!",
        "You've won! May the llama of happiness forever spit in your direction."
    ],
    lose: [
        "Fishhooks! You've lost.",
        "Hellfire and damnation! You've lost.",
        "Hissy fit with a tail on it.. you've been beaten.",
        "Swat my hind with a melon rind.. you've been beaten.",
        "Sweet fancy Moses on buttered toast.. you've been had.",
        "What in tarnation! You've lost!",
        "Dagnabbit! You've lost.",
        "Everyone's a winner (except on tapreallyfast.com)",
        "You win! JK, you lost.",
        "My dear chap, I'm sorry to say, but you've lost.",
        "Monkeybutters! You've lost!",
        "Sweet lemony Lincoln, you've lost!",
        "KALUMPUS! You've lost!",
        "Another tapper has out-tapped you!",
        "Roses are red, violets are blue, the other person has out-tapped you.",
        "Twisted armpit hairs!! You've lost!",
        "RAZZBERRIES! You've lost!",
        "POOP FIRE AND FLAMES! You've lost!",
        "Sniv! You've lost",
        "Son of a seawolf! You've lost!",
        "Thunderation! You've lost!",
        "Tough noogies! You've lost.",
        "What the dickens! You've lost!",
        "Brandy Swallowbits! You've lost!",
        "Close but no cigar! You've lost"
    ]
};


export default phrases;