import React, { Component, Fragment } from "react";
import styles from "../waiting/waiting.module.css";
import Game from "../game/game";
export default class loading extends Component {
  state = {
    count: 5,
    loading: true,
  };
  componentDidMount() {
    setInterval(() => {
      if (this.state.count > 1) {
        this.setState({ count: this.state.count - 1 });
      } else {
        this.setState({ loading: false });
      }
    }, 1000);
  }
  render() {
    return (
      <Fragment>
        {(!this.state.loading && <Game socket={this.props.socket} bot={this.props.bot}></Game>) || (
          <div className={styles.container}>
            <div>
              <h1 className={styles.title}>Your game will begin in </h1>
            </div>
            <div className={styles.text}>
              <h2 className={styles.count}>{this.state.count}</h2>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
